<template>
  <div class="wrapper">
    <n-result
      :status="$route.params.status"
      :title="$route.params.status"
      description="Oops there was an error!"
    >
    </n-result>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  box-shadow: 0 0 20px var(--shadow);

  .n-result {
    padding: 50px;
  }
  .n-result-footer {
    display: none !important;
  }
}
</style>
